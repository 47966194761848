import axios from "axios"
import { QuizData } from "./Questions";
import { Lang } from "../internationalization/Lang";

export class Service {
    constructor(private basePath: string) {
    }

    async insertQuestionData(companyName: string, country: string, email: string, questionData: boolean[][], scores: { [key: string]: number }, lang: Lang, quizGroup?: string) {
        let answers: { csvName: string, value: string[] }[] = [
            {csvName: 'companyName', value: [companyName]},
            {csvName: 'located', value: [country]},
            {csvName: 'eMail', value: [email]}
        ]

        for (let section = 0; section < 5; section++) {
            for (let question = 0; question < 6; question++) {
                answers.push({csvName: `${QuizData[section].type}${question}`, value: [Boolean(questionData[section][question]).toString()]})
            }
        }

        if (quizGroup) {
            answers.push({csvName: 'quiz-group', value: [quizGroup]})
        }

        let response = await axios.post(this.basePath + "/import/Readiness/answers", {answers: answers, scores: scores, language: lang.language})
        if (response.status !== 200) {
            alert(lang.get("error.couldNotContactServer"))
            console.error(`${response.status} - ${response.statusText}`)
        }
    }
}
