import React, { useCallback, useState } from 'react';
import 'normalize.css';
import './App.scss';
import { HomePage } from './pages/HomePage';
import { QuizResults } from "./pages/QuizResults";
import { QuizQuestions } from "./pages/QuizQuestions";
import { Lang } from './internationalization/Lang';

enum PageShown {
	Home,
	Question,
	Result
}

export const LangContext = React.createContext(new Lang('EN'))

export type LanguageType = 'EN' | 'FR' | 'ES' | 'CH';

const App: React.FC = () => {

	let language: LanguageType = 'EN'
	let initialLanguage = (new URL(window.location.href).searchParams.get('lang') ?? '').toUpperCase()
	if (initialLanguage === 'FR' || initialLanguage === 'EN' || initialLanguage === 'ES' || initialLanguage === 'CH') {
		language = initialLanguage
	}

	let [l, setl] = useState<Lang>(new Lang(language))


	let [shownPage, setShownPage] = useState<PageShown>(PageShown.Home)
	let [results, setResults] = useState<boolean[][]>([
		// [true, false, true, true, false, false],
		// [true, false, true, true, false, false],
		// [true, false, true, true, false, false],
		// [true, false, true, true, false, false],
		// [true, false, true, true, false, false]
	])

	let onStart = useCallback(() => {
		setShownPage(PageShown.Question)
	}, [])

	let onQuizComplete = useCallback((quizResults: boolean[][]) => {
		setShownPage(PageShown.Result)
		setResults(quizResults)
	}, []);

	let onLangChange = useCallback((lang: Lang) => {
		 setl(lang)
	}, []);

	return (
		<React.Fragment>
			<LangContext.Provider value={l}>
				{shownPage === PageShown.Home ? <HomePage onStart={onStart} onLangChange={(lang: Lang) => onLangChange(lang)} /> : ''}
				{shownPage === PageShown.Question ? <QuizQuestions onQuizComplete={onQuizComplete} /> : ''}
				{shownPage === PageShown.Result ? <QuizResults results={results} /> : ''}
			</LangContext.Provider>
		</React.Fragment>
	)
}

export default App;
