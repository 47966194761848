export const countries = [
    {code: 1, name: "Afghanistan"},
    {code: 2, name: "Albania"},
    {code: 3, name: "Algeria"},
    {code: 4, name: "American Samoa"},
    {code: 5, name: "Andorra"},
    {code: 6, name: "Angola"},
    {code: 7, name: "Anguilla"},
    {code: 8, name: "Antigua & Barbuda"},
    {code: 9, name: "Argentina"},
    {code: 10, name: "Armenia"},
    {code: 11, name: "Aruba"},
    {code: 12, name: "Australia"},
    {code: 13, name: "Austria"},
    {code: 14, name: "Azerbaijan"},
    {code: 15, name: "Bahamas, The"},
    {code: 16, name: "Bahrain"},
    {code: 17, name: "Bangladesh"},
    {code: 18, name: "Barbados"},
    {code: 19, name: "Belarus"},
    {code: 20, name: "Belgium"},
    {code: 21, name: "Belize"},
    {code: 22, name: "Benin"},
    {code: 23, name: "Bermuda"},
    {code: 24, name: "Bhutan"},
    {code: 25, name: "Bolivia"},
    {code: 26, name: "Bosnia & Herzegovina"},
    {code: 27, name: "Botswana"},
    {code: 28, name: "Brazil"},
    {code: 29, name: "British Virgin Is."},
    {code: 30, name: "Brunei"},
    {code: 31, name: "Bulgaria"},
    {code: 32, name: "Burkina Faso"},
    {code: 33, name: "Burma"},
    {code: 34, name: "Burundi"},
    {code: 35, name: "Cambodia"},
    {code: 36, name: "Cameroon"},
    {code: 37, name: "Canada"},
    {code: 38, name: "Cape Verde"},
    {code: 39, name: "Cayman Islands"},
    {code: 40, name: "Central African Rep."},
    {code: 41, name: "Chad"},
    {code: 42, name: "Chile"},
    {code: 43, name: "China"},
    {code: 44, name: "Colombia"},
    {code: 45, name: "Comoros"},
    {code: 46, name: "Congo, Dem. Rep."},
    {code: 47, name: "Congo, Repub. of the"},
    {code: 48, name: "Cook Islands"},
    {code: 49, name: "Costa Rica"},
    {code: 50, name: "Cote d'Ivoire"},
    {code: 51, name: "Croatia"},
    {code: 52, name: "Cuba"},
    {code: 53, name: "Cyprus"},
    {code: 54, name: "Czech Republic"},
    {code: 55, name: "Denmark"},
    {code: 56, name: "Djibouti"},
    {code: 57, name: "Dominica"},
    {code: 58, name: "Dominican Republic"},
    {code: 59, name: "East Timor"},
    {code: 60, name: "Ecuador"},
    {code: 61, name: "Egypt"},
    {code: 62, name: "El Salvador"},
    {code: 63, name: "Equatorial Guinea"},
    {code: 64, name: "Eritrea"},
    {code: 65, name: "Estonia"},
    {code: 66, name: "Ethiopia"},
    {code: 67, name: "Faroe Islands"},
    {code: 68, name: "Fiji"},
    {code: 69, name: "Finland"},
    {code: 70, name: "France"},
    {code: 71, name: "French Guiana"},
    {code: 72, name: "French Polynesia"},
    {code: 73, name: "Gabon"},
    {code: 74, name: "Gambia, The"},
    {code: 75, name: "Gaza Strip"},
    {code: 76, name: "Georgia"},
    {code: 77, name: "Germany"},
    {code: 78, name: "Ghana"},
    {code: 79, name: "Gibraltar"},
    {code: 80, name: "Greece"},
    {code: 81, name: "Greenland"},
    {code: 82, name: "Grenada"},
    {code: 83, name: "Guadeloupe"},
    {code: 84, name: "Guam"},
    {code: 85, name: "Guatemala"},
    {code: 86, name: "Guernsey"},
    {code: 87, name: "Guinea"},
    {code: 88, name: "Guinea-Bissau"},
    {code: 89, name: "Guyana"},
    {code: 90, name: "Haiti"},
    {code: 91, name: "Honduras"},
    {code: 92, name: "Hong Kong"},
    {code: 93, name: "Hungary"},
    {code: 94, name: "Iceland"},
    {code: 95, name: "India"},
    {code: 96, name: "Indonesia"},
    {code: 97, name: "Iran"},
    {code: 98, name: "Iraq"},
    {code: 99, name: "Ireland"},
    {code: 100, name: "Isle of Man"},
    {code: 101, name: "Israel"},
    {code: 102, name: "Italy"},
    {code: 103, name: "Jamaica"},
    {code: 104, name: "Japan"},
    {code: 105, name: "Jersey"},
    {code: 106, name: "Jordan"},
    {code: 107, name: "Kazakhstan"},
    {code: 108, name: "Kenya"},
    {code: 109, name: "Kiribati"},
    {code: 110, name: "Korea, North"},
    {code: 111, name: "Korea, South"},
    {code: 112, name: "Kuwait"},
    {code: 113, name: "Kyrgyzstan"},
    {code: 114, name: "Laos"},
    {code: 115, name: "Latvia"},
    {code: 116, name: "Lebanon"},
    {code: 117, name: "Lesotho"},
    {code: 118, name: "Liberia"},
    {code: 119, name: "Libya"},
    {code: 120, name: "Liechtenstein"},
    {code: 121, name: "Lithuania"},
    {code: 122, name: "Luxembourg"},
    {code: 123, name: "Macau"},
    {code: 124, name: "Macedonia"},
    {code: 125, name: "Madagascar"},
    {code: 126, name: "Malawi"},
    {code: 127, name: "Malaysia"},
    {code: 128, name: "Maldives"},
    {code: 129, name: "Mali"},
    {code: 130, name: "Malta"},
    {code: 131, name: "Marshall Islands"},
    {code: 132, name: "Martinique"},
    {code: 133, name: "Mauritania"},
    {code: 134, name: "Mauritius"},
    {code: 135, name: "Mayotte"},
    {code: 136, name: "Mexico"},
    {code: 137, name: "Micronesia, Fed. St."},
    {code: 138, name: "Moldova"},
    {code: 139, name: "Monaco"},
    {code: 140, name: "Mongolia"},
    {code: 141, name: "Montserrat"},
    {code: 142, name: "Morocco"},
    {code: 143, name: "Mozambique"},
    {code: 144, name: "Namibia"},
    {code: 145, name: "Nauru"},
    {code: 146, name: "Nepal"},
    {code: 147, name: "Netherlands"},
    {code: 148, name: "Netherlands Antilles"},
    {code: 149, name: "New Caledonia"},
    {code: 150, name: "New Zealand"},
    {code: 151, name: "Nicaragua"},
    {code: 152, name: "Niger"},
    {code: 153, name: "Nigeria"},
    {code: 154, name: "N. Mariana Islands"},
    {code: 155, name: "Norway"},
    {code: 156, name: "Oman"},
    {code: 157, name: "Pakistan"},
    {code: 158, name: "Palau"},
    {code: 228, name: "Palestine"},
    {code: 159, name: "Panama"},
    {code: 160, name: "Papua New Guinea"},
    {code: 161, name: "Paraguay"},
    {code: 162, name: "Peru"},
    {code: 163, name: "Philippines"},
    {code: 164, name: "Poland"},
    {code: 165, name: "Portugal"},
    {code: 166, name: "Puerto Rico"},
    {code: 167, name: "Qatar"},
    {code: 168, name: "Reunion"},
    {code: 169, name: "Romania"},
    {code: 170, name: "Russia"},
    {code: 171, name: "Rwanda"},
    {code: 172, name: "Saint Helena"},
    {code: 173, name: "Saint Kitts & Nevis"},
    {code: 174, name: "Saint Lucia"},
    {code: 175, name: "St Pierre & Miquelon"},
    {code: 176, name: "Saint Vincent and the Grenadines"},
    {code: 177, name: "Samoa"},
    {code: 178, name: "San Marino"},
    {code: 179, name: "Sao Tome & Principe"},
    {code: 180, name: "Saudi Arabia"},
    {code: 181, name: "Senegal"},
    {code: 182, name: "Serbia"},
    {code: 183, name: "Seychelles"},
    {code: 184, name: "Sierra Leone"},
    {code: 185, name: "Singapore"},
    {code: 186, name: "Slovakia"},
    {code: 187, name: "Slovenia"},
    {code: 188, name: "Solomon Islands"},
    {code: 189, name: "Somalia"},
    {code: 190, name: "South Africa"},
    {code: 191, name: "Spain"},
    {code: 192, name: "Sri Lanka"},
    {code: 193, name: "Sudan"},
    {code: 194, name: "Suriname"},
    {code: 195, name: "Swaziland"},
    {code: 196, name: "Sweden"},
    {code: 197, name: "Switzerland"},
    {code: 198, name: "Syria"},
    {code: 199, name: "Taiwan"},
    {code: 200, name: "Tajikistan"},
    {code: 201, name: "Tanzania"},
    {code: 202, name: "Thailand"},
    {code: 203, name: "Togo"},
    {code: 204, name: "Tonga"},
    {code: 205, name: "Trinidad & Tobago"},
    {code: 206, name: "Tunisia"},
    {code: 207, name: "Turkey"},
    {code: 208, name: "Turkmenistan"},
    {code: 209, name: "Turks & Caicos Is"},
    {code: 210, name: "Tuvalu"},
    {code: 211, name: "Uganda"},
    {code: 212, name: "Ukraine"},
    {code: 213, name: "United Arab Emirates"},
    {code: 214, name: "United Kingdom"},
    {code: 215, name: "United States"},
    {code: 216, name: "Uruguay"},
    {code: 217, name: "Uzbekistan"},
    {code: 218, name: "Vanuatu"},
    {code: 219, name: "Venezuela"},
    {code: 220, name: "Vietnam"},
    {code: 221, name: "Virgin Islands"},
    {code: 222, name: "Wallis and Futuna"},
    {code: 223, name: "West Bank"},
    {code: 224, name: "Western Sahara"},
    {code: 225, name: "Yemen"},
    {code: 226, name: "Zambia"},
    {code: 227, name: "Zimbabwe"}
]