import React, { ReactNode, useContext } from "react"
import "./QuizCard.scss"
import { LangContext } from "../../App";

interface QuizCardProps {
	sectionNumber: number
	title: string
	questionDescription: React.ReactNode

	onYes: () => void
	onNo: () => void
}

export const QuizCard = (props: QuizCardProps) => {
	const lang = useContext(LangContext)
	return (<main className="main">
		<div className="quiz container container--full">
			<div className="column">

				<section className="quiz-card quiz-card--questions">
					<header className="quiz-card__header">
						<div className="quiz-card__title-section">{lang.get("quiz.label.section")} {props.sectionNumber}</div>
						<h1 className="quiz-card__title">{props.title}</h1>
					</header>

					<main className="quiz-card__description quiz-card__description--card">{props.questionDescription}</main>

					<footer className="quiz-card__footer button-group">
						<button onClick={props.onNo} className="button secondary">{lang.get("quiz.btn.no")}</button>
						<button onClick={props.onYes} className="button">{lang.get("quiz.btn.yes")}</button>
					</footer>

				</section>

				{/*<div className="quiz-arrows">*/}
				{/*	<a href="/" className="arrow arrow--left">Back</a>*/}
				{/*	<a href="/" className="arrow arrow--right">Next</a>*/}
				{/*</div>*/}

			</div>
		</div>
	</main>);
};
