import React, { useContext } from "react";
import './Footer.scss';
import { LangContext } from "../../App";

export const Footer = () => {
    const lang = useContext(LangContext)
    return (
		<footer className="footer">
			<div className="container">
				<div className="column">
					<p><a href="https://bitanalytics.io" target="_blank" rel="noopener noreferrer">{lang.get("footer.label.poweredBy")}</a></p>
				</div>
			</div>
		</footer>
    )
}