import React, { PropsWithChildren, useEffect, useRef, useContext } from 'react';
import './Popup.scss';
import useForm from "react-hook-form";
import { countries } from "../../assets/countries";
import { LangContext } from '../../App';

interface PopupProps {
	isActive: boolean
}

export const Popup = (props: PropsWithChildren<PopupProps>) => {
	let activeClass = ""
	if (props.isActive) {
		activeClass = "popup--active"
	}

	return (
		<section className={`main popup ${activeClass}`}>
			<div className="container">
				<section className="quiz-card popup__card">
					{props.children}
				</section>
			</div>
		</section>
	)
}


export interface PopupFormData {
	companyName: string
	country: string
	email: string
}

interface PopupFormProps {
	onSendResults: (formData: PopupFormData) => void
}

export const PopupForm = (props: PopupFormProps) => {
	const lang = useContext(LangContext)
	const form = useRef<HTMLFormElement>(null)
	const { register, handleSubmit, errors } = useForm<PopupFormData>()
	const onSubmit = handleSubmit((data: PopupFormData) => {
		props.onSendResults(data)
	})

	useEffect(() => {
		form.current!.focus();
	}, [])

	if (errors.companyName || errors.email) alert(lang.get("sendForm.alert.allFieldsAreRequired"))

	return (
		<form className="quiz-card__form" onSubmit={onSubmit} ref={form}>
			<PopupHeader title={lang.get("sendForm.title.sendResults")} />
			<main className="quiz-card__main">
				<div className="quiz-card__fields">
					<input type="text" placeholder={lang.get("sendForm.placeholder.companyName")} name="companyName" ref={register({ required: true })} />
					<select placeholder={lang.get("sendForm.placeholder.country")} name="country" ref={register({ required: true })} defaultValue="13">
						{countries.map(it => <option value={it.code.toString()}>{it.name}</option>)}
					</select>
					<input type="email" placeholder={lang.get("sendForm.placeholder.email")} name="email" ref={register({ required: true })} />
					<small>
						<span>
							{lang.get("sendForm.label.privacyPart1")} <a target="_blank"
								href="https://itc-development.s3.eu-central-1.amazonaws.com/static/web-files/ecomConnect+Privacy+Policy.pdf"
								rel="noopener noreferrer">
								{lang.get("sendForm.label.privacyPart2")}
							</a>
						</span>
					</small>
				</div>
			</main>
			<footer className="results__footer">
				<button className="button" type="submit">{lang.get("sendForm.btn.sendResults")}</button>
			</footer>
		</form>

	)
}

export const PopupSuccess = () => {
	const lang = useContext(LangContext)
	return (
		<div>
			<PopupHeader title={<React.Fragment>{lang.getDangerous("sendForm.title.resultsSent")}</React.Fragment>} />
			<div className="results__footer">
				<button className="button" type="submit" onClick={() => window.location.reload()}>{lang.get("sendForm.btn.close")}</button>
			</div>
		</div>
	)
}

interface PopupHeaderProps {
	title: string | React.ReactNode
}

export const PopupHeader = (props: PopupHeaderProps) => {
	return (
		<header className="quiz-card__header popup__header">
			<div className="quiz-card__icon popup__icon" />
			<h1 className="quiz-card__title">{props.title}</h1>
		</header>
	)
}