import React, { useContext } from "react"
import "../quiz-card/QuizCard.scss"
import "./Results.scss"
import { FinalResult } from "../../data/Questions";
import { LangContext } from "../../App";

export interface ResultsItem {
    title: string,
    tooltip: string,
    achievement: 0 | 1 | 2
}

interface ResultsProps {
    globalAchievement: 0 | 1 | 2
    sectionResults: ResultsItem[]

    onSendResults: () => void
}

export const Results = (props: ResultsProps) => {
    const lang = useContext(LangContext)
    let achievements = [lang.get("result.title.beginner"), lang.get("result.title.intermediate"), lang.get("result.title.expert")]
    return (
        <main className="main">
            <div className="container">
                <section className="quiz-card">
                    <header className="quiz-card__header">
                        <div className="quiz-card__icon" />
                        <h1 className="quiz-card__title">{lang.get("result.title")}</h1>
                        <h2 className="quiz-card__subtitle">{achievements[props.globalAchievement]}</h2>
                    </header>
                    <main className="quiz-card__main">
                        <div className="quiz-card__description">
                            <p>{lang.getDangerous(FinalResult.filter(it => it.achievement === props.globalAchievement)[0].text)}</p>
                            {/* <p dangerouslySetInnerHTML={{ __html:  }} /> */}
                        </div>
                        <div className="results">
                            {props.sectionResults.map(it =>
                                <div className="results__row">

                                    <div className="results__column">
                                        <header className="results__header">
                                            <h3 className="results__title">{it.title}</h3>
                                        </header>
                                        <div className="results__subtitle">
                                            <div className="results__wrap">
                                                <div className="results__icon" />
                                                <div className="results__tooltip">
                                                    <h4 className="results__tooltip--title">{it.title} - {achievements[it.achievement]}</h4>
                                                    <p className="results__tooltip--description">{it.tooltip}</p>
                                                </div>
                                                <div className="results__achievement">
                                                    {achievements[it.achievement]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="results__column">
                                        <div className="results__stars">
                                            <div className={`results__star`} />
                                            <div className={`results__star ${it.achievement < 1 ? "results__star--outline" : ""}`} />
                                            <div className={`results__star ${it.achievement < 2 ? "results__star--outline" : ""}`} />
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    </main>
                    <footer className="results__footer">
                        <button onClick={props.onSendResults} className="button">{lang.get("result.btn.sendResults")}</button>
                    </footer>
                </section>
            </div>
        </main>
    )
}