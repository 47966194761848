import React, { useCallback, useState, useContext } from "react";
import { Header } from "../components/header/Header";
import { Footer } from '../components/footer/Footer';
import { Hero } from "../components/hero/Hero";
import { QuizCard } from "../components/quiz-card/QuizCard";
import { QuizData } from "../data/Questions";
import business from '../assets/BusinessStratPlan.jpg';
import onlinePresence from '../assets/OnlinePresence.jpg';
import digitalMkt from '../assets/DigitalMkt.jpg';
import logistics from '../assets/Logistics.jpg';
import customerService from '../assets/CustomerService.jpg';
import { LangContext } from "../App";

interface QuizQuestionsProps {
	onQuizComplete: (results: boolean[][]) => void
}

const quizBeginningState: (boolean | undefined)[][] = [
	[undefined, undefined, undefined, undefined, undefined, undefined],
	[undefined, undefined, undefined, undefined, undefined, undefined],
	[undefined, undefined, undefined, undefined, undefined, undefined],
	[undefined, undefined, undefined, undefined, undefined, undefined],
	[undefined, undefined, undefined, undefined, undefined, undefined]
]

export const QuizQuestions = (props: QuizQuestionsProps) => {
	const lang = useContext(LangContext)
	let [sectionIndex, setSectionIndex] = useState(0)
	let [questionIndex, setQuestionIndex] = useState(0)
	let [questionState, setQuestionState] = useState<(boolean | undefined)[][]>(quizBeginningState)

	let bgImages = [business, onlinePresence, digitalMkt, logistics, customerService]

	let onYesOrNoAnswer = useCallback((answer: boolean) => {
		questionState[sectionIndex][questionIndex] = answer
		// check if user answered false
		if (!answer) {
			// set all remaining questions in this section to false
			for (let i = questionIndex; i < 6; i++) {
				questionState[sectionIndex][i] = false
			}

			// continue on to next section
			if (sectionIndex === 4) {
				props.onQuizComplete(questionState as boolean[][])
				return
			} else {
				setSectionIndex(sectionIndex + 1)
				setQuestionIndex(0)
				return
			}
		}

		setQuestionState(questionState)
		if (questionIndex === 5) {
			if (sectionIndex === 4) {
				// we are at the end of quiz, show results
				props.onQuizComplete(questionState as boolean[][])
				return
			}
			setSectionIndex(sectionIndex + 1)
			setQuestionIndex(0)
		} else {
			setQuestionIndex(questionIndex + 1)
		}
	}, [props, questionIndex, questionState, sectionIndex])

	let onYes = useCallback(() => {
		onYesOrNoAnswer(true)
	}, [onYesOrNoAnswer])

	let onNo = useCallback(() => {
		onYesOrNoAnswer(false)
	}, [onYesOrNoAnswer])

	// let description = <p>Has your company decided to start e-commerce activities (or it is willing to) after a thorough examination of the different preparaqtions required (strategy,
	// 	e-payments, shipping, customer service, etc.)?</p>;

	return (
		<React.Fragment>
			<Header progressStep={sectionIndex} />
			<Hero imageURL={bgImages[sectionIndex]} />
			<QuizCard
				sectionNumber={sectionIndex + 1}
				title={lang.get(QuizData[sectionIndex].typeName)}
				questionDescription={<p>{lang.get(QuizData[sectionIndex].questions[questionIndex])}</p>}
				onYes={onYes}
				onNo={onNo}
			/>
			<Footer />
		</React.Fragment>
	)
}
