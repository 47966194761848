export enum SectionType {
    Planning = "Planning",
    OnlinePresence = "OnlinePresence",
    DigitalMarketing = "DigitalMarketing",
    ShippingAndInventory = "ShippingAndInventory",
    CustomerService = "CustomerService"
}

export interface Section {
    type: SectionType
    typeName: string,
    questions: string[]
    results: string[]
}

export const FinalResult = [{
    achievement: 0,
    text: "result.achievement.title.beginner"
}, {
    achievement: 1,
    text: "result.achievement1.title.intermediate"
}, {
    achievement: 2,
    text: "result.achievement2.title.expert"
}]

export const QuizData: Section[] = [
    {
        type: SectionType.Planning,
        typeName: "section.planning",
        questions: [
            "question.planning.1",
            "question.planning.2",

            "question.planning.3",
            "question.planning.4",

            "question.planning.5",
            "question.planning.6"
        ],
        results: [
            "result.planning.beginner",
            "result.planning.intermediate",
            "result.planning.expert"
        ]
    },
    {
        type: SectionType.OnlinePresence,
        typeName: "section.onlinePresence",
        questions: [
            "question.onlinePresence.1",
            "question.onlinePresence.2",

            "question.onlinePresence.3",
            "question.onlinePresence.4",

            "question.onlinePresence.5",
            "question.onlinePresence.6"
        ],
        results: [
            "result.onlinePresence.beginner",
            "result.onlinePresence.intermediate",
            "result.onlinePresence.expert"
        ]
    },
    {
        type: SectionType.DigitalMarketing,
        typeName: "section.digitalMarketing",
        questions: [
            "question.digitalMarketing.1",
            "question.digitalMarketing.2",

            "question.digitalMarketing.3",
            "question.digitalMarketing.4",

            "question.digitalMarketing.5",
            "question.digitalMarketing.6"
        ],
        results: [
            "result.digitalMarketing.beginner",
            "result.digitalMarketing.intermediate",
            "result.digitalMarketing.expert"
        ]
    },
    {
        type: SectionType.ShippingAndInventory,
        typeName: "section.shippingAndInventory",
        questions: [
            "question.shippingAndInventory.1",
            "question.shippingAndInventory.2",

            "question.shippingAndInventory.3",
            "question.shippingAndInventory.4",

            "question.shippingAndInventory.5",
            "question.shippingAndInventory.6"
        ],
        results: [
            "result.shippingAndInventory.beginner",
            "result.shippingAndInventory.intermediate",
            "result.shippingAndInventory.expert"
        ]
    },
    {
        type: SectionType.CustomerService,
        typeName: "section.customerService",
        questions: [
            "question.customerService.1",
            "question.customerService.2",

            "question.customerService.3",
            "question.customerService.4",

            "question.customerService.5",
            "question.customerService.6"
        ],
        results: [
            "result.customerService.beginner",
            "result.customerService.intermediate",
            "result.customerService.expert"
        ]
    }
]
