import React, { useContext } from "react";
import logo from '../../assets/logo.svg';
import logoWhite from '../../assets/full_logo.png';
import "./Header.scss"
import { LangContext } from "../../App";
import { Lang } from "../../internationalization/Lang";

interface HeaderProps {
    isTransparent?: boolean
    progressStep?: number
    onLangChange?: (lang: Lang) => void
}

export const Header = (props: HeaderProps) => {
    const lang = useContext(LangContext)
    let isTransparent = false
    if (props.isTransparent !== undefined)
        isTransparent = props.isTransparent

    let headerClass = ''

    if (isTransparent) {
        headerClass = 'header--transparent'
    }

    function calculateProgressStepClass(stepIndex: number) {
        if (props.progressStep === stepIndex) return "progress__step--active"
        else if (props.progressStep !== undefined && props.progressStep > stepIndex) return "progress__step--checked"
        else return ""
    }

    return (
        <header className={`header ${headerClass}`}>
            <div className="header__border" />
            <div className="container align-middle">
                <div className="column">
                    <div className="header__logo__wrapper">
                        <a href="https://ecomconnect.org/">
                            <img src={logoWhite} className="header__logo header__logo--primary" alt="" />
                            <img src={logo} className="header__logo header__logo--secondary" alt="" />
                        </a>
                    </div>
                </div>
                <div className="lang column">
                    <button onClick={() => props.onLangChange!(new Lang("EN"))}>EN</button>
                    <span className="lang__separator"/>
                    <button onClick={() => props.onLangChange!(new Lang("FR"))}>FR</button>
                    <span className="lang__separator"/>
                    <button onClick={() => props.onLangChange!(new Lang("ES"))}>ES</button>
                    <span className="lang__separator"/>
                    <button onClick={() => props.onLangChange!(new Lang("CH"))}>CH</button>
                </div>
                <div className="progress column">
                    <span className="progress__title">{lang.get('header.label.progress')}</span>
                    <div className="progress__bar">
                        <div className={`progress__step ${calculateProgressStepClass(0)}`} />
                        <div className={`progress__step ${calculateProgressStepClass(1)}`} />
                        <div className={`progress__step ${calculateProgressStepClass(2)}`} />
                        <div className={`progress__step ${calculateProgressStepClass(3)}`} />
                        <div className={`progress__step ${calculateProgressStepClass(4)}`} />
                    </div>
                </div>
            </div>
        </header>
    )
}
