import React, { ReactNode, useContext } from "react";
import "./Headline.scss"
import { LangContext } from "../../App";

interface HeadlineProps {
	title: React.ReactNode
	description: React.ReactNode
	onStartQuiz: () => void
}

export const Headline = (props: HeadlineProps) => {
	const lang = useContext(LangContext)
	return (
		<main className="main">
			<div className="container">
				<div className="column">

					<section className="headline">

						<header className="headline__header">
							<h1 className="headline__title">{props.title}</h1>
						</header>

						<main className="headline__description">{props.description}</main>

						<footer className="headline__footer">
							<button className="button" onClick={() => props.onStartQuiz()}>{lang.get("home.btn.startQuiz")}</button>
						</footer>

					</section>

				</div>
			</div>
		</main>
	)
}