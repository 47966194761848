import React, { useContext } from "react";
import { Header } from "../components/header/Header";
import { Hero } from "../components/hero/Hero";
import { Headline } from "../components/headline/Headline";
import heroImage from '../assets/LandingPage.jpg';
import { LangContext } from "../App";
import { Lang } from "../internationalization/Lang";
import { Footer } from "../components/footer/Footer";

export const HomePage = (props: { onStart: () => void, onLangChange: (lang: Lang) => void }) => {
	const lang = useContext(LangContext)
	return (
		<React.Fragment>
			<Header isTransparent={true} onLangChange={(lang: Lang) => props.onLangChange(lang)} />
			<Hero
				imageURL={heroImage}
			/>
			<Headline
				onStartQuiz={props.onStart}
				title={<span>{lang.getDangerous("home.title")}</span>}
				description={<p>{lang.get("home.description")}</p>}
			/>
			<Footer/>
		</React.Fragment>
	)
}
