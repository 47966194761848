import React from "react"
import './Hero.scss'

interface HeroProps {
    imageURL?: string | React.ReactNode
}

export const Hero = (props: HeroProps) => {
    return (
        <React.Fragment>
            <section className="hero" style={{ backgroundImage: `url(${props.imageURL})` }} />
        </React.Fragment>
    )
}