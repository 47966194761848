import React, { useCallback, useState, useContext } from "react";
import { Header } from "../components/header/Header";
import { Footer } from '../components/footer/Footer';
import { Hero } from "../components/hero/Hero";
import { Results, ResultsItem } from "../components/results/Results";
import { Popup, PopupForm, PopupFormData, PopupSuccess } from "../components/popup/Popup";
import { QuizData } from "../data/Questions";
import { Service } from "../data/Service";
import heroImage from '../assets/ResultsPage.jpg';
import { LangContext } from "../App";

interface QuizResultsProps {
    results: boolean[][]
}

export const QuizResults = (props: QuizResultsProps) => {
    const lang = useContext(LangContext)
    const [showForm, setShowForm] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    // prepare data for rendering
    let sectionResults: ResultsItem[] = []
    let globalScore = 0
    let scoreResults: { [key: string]: number } = {}
    for (let sectionIdx = 0; sectionIdx < 5; sectionIdx++) {
        let sectionScore = 0
        for (let questionIdx = 0; questionIdx < 6; questionIdx++) {
            if (props.results[sectionIdx][questionIdx]) sectionScore++
        }

        let achievement: 0 | 1 | 2 = 0
        if (sectionScore <= 2) achievement = 0
        else if (sectionScore <= 4) achievement = 1
        else achievement = 2

        sectionResults.push({
            achievement: achievement,
            title: lang.get(QuizData[sectionIdx].typeName),
            tooltip: lang.get(QuizData[sectionIdx].results[achievement])
        })

        globalScore += achievement + 1
        scoreResults[QuizData[sectionIdx].type] = sectionScore
    }

    let globalAchievement: 0 | 1 | 2 = 0
    if (globalScore <= 8) globalAchievement = 0
    else if (globalScore <= 12) globalAchievement = 1
    else globalAchievement = 2

    let onSendResults1 = useCallback(() => {
        setShowForm(true)
        window.scrollTo(0, 0)
    }, [])
    let onSendResults2 = useCallback(async (data: PopupFormData) => {
        try {
            const quizGroup = (new URL(window.location.href).searchParams.get('id'))?.toUpperCase()
            await new Service(process.env.REACT_APP_REST_API_URL!).insertQuestionData(data.companyName, data.country, data.email, props.results, scoreResults, lang, quizGroup)
            setShowForm(false)
            setShowSuccess(true)
        } catch (e) {
            alert(lang.get("error.sendDataToServerFailed"))
        }
    }, [lang, props.results, scoreResults])

    return (
        <React.Fragment>
            <Header progressStep={5}/>
            <Hero imageURL={heroImage}/>
            <Results globalAchievement={globalAchievement} sectionResults={sectionResults} onSendResults={onSendResults1}/>
            <Popup isActive={showForm || showSuccess}>
                {showForm ? <PopupForm onSendResults={onSendResults2}/> : ""}
                {showSuccess ? <PopupSuccess/> : ""}
            </Popup>
            <Footer/>
        </React.Fragment>
    )
}
