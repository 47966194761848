import React from 'react';
import ui from './ui.json';
import { LanguageType } from "../App";

declare global {
    interface Window { language: any; }
}

class Lang {
    language: LanguageType = 'EN';
    languageDescription = 'English';
    isDevelopmentENV = true;

    dicts: any = [
        ui
    ];


    constructor(lang: LanguageType){
        this.setLanguage(lang)
    }

    setLanguage(newLang: LanguageType) {
        this.language = newLang;
        if (newLang === 'EN') this.languageDescription = 'English';
        if (newLang === 'FR') this.languageDescription = 'French';
        if (newLang === 'ES') this.languageDescription = 'Spanish';
        if (newLang === 'CH') this.languageDescription = 'Chinese';
    }

    get(item: string) {
        for (let i = 0; i < this.dicts.length; i++) {
            if (this.dicts[i][item]) {
                if (this.dicts[i][item][this.language] === null)
                    return `--- missing ${item}/${this.language} ---`;
                else {
                    if (!this.dicts[i][item][this.language] && this.isDevelopmentENV === true)
                        return `--- missing ${item}/${this.language} ---`;
                    else
                        return this.dicts[i][item][this.language];
                }
            }
        }
        return `--- missing ${item} ---`
    }

    getDangerous(item: string) {
        return <span dangerouslySetInnerHTML={{ __html: this.get(item) }} />
    }

    todo(text: string) {
        return text;
    }
}

let lang = new Lang("EN");
window.language = lang;

export{ lang, Lang};
